.logo-burger-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: auto;
}

.navbar-container {
    padding: 12px 0;
}

@media screen and (max-width: 991px) {
    .logo-burger-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .navbar-container {
        padding: 24px 0;
    }
}



